<template>
  <NuxtLayout name="header"></NuxtLayout>
  <div class="layout-container">
    <NuxtPage/>
  </div>
  <NuxtLayout name="footer"></NuxtLayout>
  <NuxtLayout name="sidebar" v-if="$device.isDesktop"></NuxtLayout>
</template>

<style>
.layout-container {
  min-height: calc(100vh - 360px);
}
</style>
